<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="供应编码/物料编码/物料名称/备注" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.qualities" placeholder="请选择品级" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(quality, q) in qualities" :key="q" :label="quality" :value="q"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.suppliers" placeholder="请选择供应商" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getProvisions(params)">查询</el-button>
					<el-button type="primary" @click="$refs['show_provision'].open()" :disabled="!$utils.create($api.URI_MATERIALS_SUPPLIERS)">添加供应</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="provisions" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="创建人" min-width="100" prop="employee_name"></el-table-column>
				<el-table-column label="供应商家" min-width="100" prop="supplier_name"></el-table-column>
				<el-table-column label="供应编码" min-width="100" prop="no" sortable="custom"></el-table-column>
				<el-table-column label="品级" min-width="50" :formatter="({quality}) => { return qualities[quality] || '未知'; }"></el-table-column>
				<el-table-column label="供应单价" min-width="100" prop="price" sortable="custom" :formatter="({price}) => { return price/100 + ' 元'}"></el-table-column>
				<el-table-column label="物料名称" min-width="120" prop="material_name"></el-table-column>
				<el-table-column label="物料编码" min-width="100" prop="material_no"></el-table-column>
				<el-table-column label="备注说明" min-width="140" prop="remarks"></el-table-column>
				<template v-if="theme.width >= 1420">
					<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
					<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
				</template>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<template v-if="!scope.row.deleted_at">
							<el-button type="text" @click="$refs['show_provision'].open({ ...scope.row, material_id: 1, material_no: scope.row.material_no })" :disabled="!$utils.update($api.URI_MATERIALS_SUPPLIERS)">编辑</el-button>
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_MATERIALS_SUPPLIERS)">删除</el-button>
						</template>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_MATERIALS_SUPPLIERS)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getProvisions({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getProvisions({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<show-provision ref="show_provision" :qualities="qualities" :suppliers="suppliers" @refresh="getProvisions(params)"></show-provision>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import showProvision from './show';

	export default {
		components: {
			mainTable,
			showProvision
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getProvisions(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.material.name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_SUPPLIERS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getProvisions(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.material.name+'】供应吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS_SUPPLIERS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getProvisions(this.params);
					this.$message.success(msg);
				})
			},
			async getProvisions (p, l = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_SUPPLIERS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.provisions = result.data;
				this.qualities = result.qualities;
				this.suppliers = result.suppliers;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				suppliers: [],
				qualities: [],
				provisions: [],
				supplier: {},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getProvisions(this.params, true)
		}
	};
</script>